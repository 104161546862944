<template>
  <div>
    <TTabs :tabs="tabs" :activeTab="$route.query.tab" @change-tab="changeTab">
      <template #detail>
        <Order orderStore="order_payment" orderItemStore="order_items_payment">
          <template #actions>
            <TButton
              content="Download file"
              size="sm"
              color="info"
              variant="outline"
              icon="cid-cloud-download"
              @click="getUrlAttachment(payment.attachments[0].path_file)"
            />
            <TButton
              class="ml-1"
              content="Create purchase"
              size="sm"
              variant="outline"
              @click="showCreatePurchase = true"
              icon="cil-plus"
              v-if="!purchased && items.length"
            />
          </template>
        </Order>
      </template>
      <template #logs>
        <SCardLog :logableId="id" />
      </template>
      <template #lading_bill>
        <SCardLadingBillOfOrder :order="payment" />
      </template>
      <template #owning_box>
        <SCardOwningBoxOfOrder
          :owningBoxes="payment.owning_boxes_from_origin"
          :orderItems="items"
        />
      </template>
      <template #transactions>
        <SCardTransaction :objectableId="id" />
      </template>
      <template #shipment_info>
        <SCardShipmentInfo :orderId="id" :userId="payment.customer_id" />
      </template>
      <template #tracking>
        <Tracking :trackings="payment.related_trackings" />
      </template>
      <template #compensation>
        <SCardCompensationOrder :orderId="id" />
      </template>
      <template #contract>
        <SCardContract :orderId="id" />
      </template>
      <template #credit_card>
        <SCardCreditOrder :order="payment" orderStore="order_payment" />
      </template>
      <template #tracking-badge>
        <CBadge color="primary">{{ payment.related_trackings.length }}</CBadge>
      </template>
      <template #lading_bill-badge>
        <CBadge color="primary">{{ ladingBills.length }}</CBadge>
      </template>
      <template #owning_box-badge>
        <CBadge color="primary">{{
          payment.owning_boxes_from_origin.length
        }}</CBadge>
      </template>
    </TTabs>
    <CreatePurchase
      :show.sync="showCreatePurchase"
      :orderItem="items"
      v-if="items.length > 0"
      @purchase-created="fetchItems"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Order from "../components/Order.vue";
import Tracking from "../components/Tracking.vue";
import CreatePurchase from "../../components/CreatePurchase.vue";
import organizationStore from "@/core/services/tomoni/organization.local";

export default {
  components: {
    Order,
    Tracking,
    CreatePurchase,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Order detail", icon: "cis-library-books" },
        { key: "shipment_info", name: "Shipment info", icon: "cis-map" },
        { key: "contract", name: "Contract", icon: "cis-document" },
        { key: "credit_card", name: "Credit card", icon: "cis-credit-card" },
        { key: "tracking", name: "Tracking", icon: "cis-queue" },
        { key: "lading_bill", name: "Lading bill", icon: "cis-truck" },
        { key: "owning_box", name: "SKU list", icon: "cis-box" },
        { key: "compensation", name: "Compensation", icon: "cis-library" },
        { key: "logs", name: "Logs", icon: "cis-note" },
        {
          key: "transactions",
          name: "Transactions arise",
          icon: "cis-speech-notes",
        },
      ],
      showCreatePurchase: false,
    };
  },
  created() {
    if (this.id) {
      this.$store
        .dispatch("order.order_payment.detail.fetch", this.id)
        .catch(() => {
          this.$router.push("/sale/orders/payment");
        });
      this.fetchItems();
    }
  },
  computed: {
    ...mapGetters({
      payment: "order.order_payment.detail",
      ladingBills: "warehouse.lading_bills_of_orders.list",
      items: "order.order_items_payment.list",
    }),
    id() {
      return this.$route.params.id;
    },
    purchased() {
      if (this.organization_id != this.payment.sales_organization_id)
        return true;
      if (this.items.length)
        return this.items.some((x) => x.distributions?.length > 0);
      return false;
    },
    organization_id() {
      return organizationStore.get();
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
    getUrlAttachment(path_file) {
      return window.open(
        process.env.VUE_APP_ORDER_SERVICE_HOST + "/files/" + path_file
      );
    },
    fetchItems() {
      this.$store.dispatch("order.order_items_payment.push-query", {
        "filter[order_id]": this.id,
      });
    },
  },
};
</script>
