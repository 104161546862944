<template>
  <TModal
    title="Create purchase"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create"
  >
    <template #actions>
      <TButton
        :options="{ disabled: loading }"
        content="Create"
        icon="cil-plus"
        class="ml-auto"
        @click="create"
      />
    </template>
    <SSelectSupplier
      label="Supplier"
      class="mb-3"
      :value.sync="purchase.supplier_id"
      @update:value="purchase.supplier_id = $event"
    />
    <TInputMoney
      label="Additional cost"
      class="mb-3"
      :append="purchase.currency_id"
      @update:value="purchase.additional_cost = $event"
    />
    <CRow>
      <CCol col="6">
        <TInputDateTime
          class="mb-3"
          label="Payment due date"
          :value="item.payment_due_date"
          @update:value="item.payment_due_date = $event"
        />
      </CCol>
      <CCol col="6">
        <TInputDateTime
          class="mb-3"
          label="Expected delivery"
          :value="item.expected_delivery"
          @update:value="item.expected_delivery = $event"
        />
      </CCol>
    </CRow>
    <hr />
    <div
      v-for="item in orderItem"
      :key="item.id"
      class="border-primary rounded mb-2 p-2"
    >
      <SCardProductItems :item="item.product" />
      <TTableAsForm
        :data="item"
        :fields="itemFields"
        :splitLeft="6"
        :addRowClasses="[]"
        class="mt-2"
      >
        <template #price="{ value }">
          <TMessageMoney :amount="value" :currency="item.currency_id" />
        </template>
        <template #tax_percent="{ value }">
          <TMessagePercent :value="value" />
        </template>
        <template #quantity="{ value }">
          <TMessageNumber :value="value" />
        </template>
        <template #balance="{ value }">
          <TMessageMoney :amount="value" :currency="item.currency_id" />
        </template>
      </TTableAsForm>
    </div>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderItem: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      item: {
        expected_delivery: 0,
        payment_due_date: 0,
      },
      purchase: {
        currency_id: "",
      },
      itemFields: [
        { key: "price", label: "Price" },
        { key: "tax_percent", label: "Tax percent" },
        { key: "quantity", label: "Quantity" },
        { key: "balance", label: "Total amount" },
      ],
    };
  },
  watch: {
    orderItem: {
      immediate: true,
      handler(items) {
        if (items.length) {
          this.purchase.currency_id = items[0].currency_id;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      loading: "order.purchases.loading",
    }),
  },
  methods: {
    create() {
      let data = {};
      this.orderItem.forEach((item, index) => {
        const purchaseItem = {
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          tax_percent: item.tax_percent,
          ...this.item,
        };
        const purchaseItemConvert = {
          ...this.lodash.mapKeys(purchaseItem, (value, key) => {
            return `items[${index}][${key}]`;
          }),
        };
        const distribute = {
          [`items[${index}][distributions][0][order_item_id]`]: item.id,
          [`items[${index}][distributions][0][quantity]`]: item.quantity,
          [`items[${index}][distributions][0][price]`]: item.balance,
        };
        data = { ...data, ...purchaseItemConvert, ...distribute };
      });
      this.$store
        .dispatch(
          "order.purchases.create",
          this.lodash.pickBy(
            { ...data, ...this.purchase },
            this.lodash.identity
          )
        )
        .then(() => {
          this.$emit("purchase-created");
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
